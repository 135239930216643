import React from "react"

import Layout from "../components/layout"
import LinkButton from "../components/link-button"
import SEO from "../components/seo"

import companyLocationImage from "../assets/images/company-location.png"

const ContactPage = () => (
  <Layout>
    <SEO title="Variety Show Studios Contact" keywords={[`variety show studios`, `galactic rex`, `games`, `mobile`, `applications`]} />
    <section>
      <div className="contact-content">
        <p className="contact__title">
          Want to get in touch?
        </p>
        <p className="contact__text">
          Feel free to drop us a message and we'll get 
          back to you as soon as possible!
        </p>
        <LinkButton title="Contact Us" href="mailto:info@varietyshowstudios.com" />
      </div>
    </section>

    <section>
      <div className="contact-address">
        <h2 className="contact-address__company">Variety Show Studios<br/>LLC</h2>
        <div className="contact-address__street">
          1545 NW Market Street Apt 205<br/>
          Seattle, WA 98107
        </div>
        <div className="contact-address__location">
          <img src={companyLocationImage} alt="Variety Show Studios LLC is located in Seattle, Washington"/>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactPage